import {createValidator} from '../rules/createValidator';
import {required} from '../rules/required';
import {email} from '../rules/email';
import {firstName} from '../rules/firstName';
import {lastName} from '../rules/lastName';
import {min} from '../rules/min';
import {max} from '../rules/max';
import {website} from '../rules/website';
import {company} from '../rules/company';
import {industry} from '../rules/industry';
import {lowercase} from '../rules/lowercase';
import {uppercase} from '../rules/uppercase';
import {character} from '../rules/character';
import {password} from '../rules/password';
import {number} from '../rules/number';

export const validator = createValidator({
	email: [required, email],
	first_name: [required, firstName],
	last_name: [required, lastName],
	company: [required, company],
	industry: [required, industry],
	website: [required, website],
	password: [required, lowercase, uppercase, number, character, min(12), max(24)],
	confirmPassword: [required, password],
	'g-recaptcha-response': [required],
});
